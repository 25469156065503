.ant-upload-list-picture-card .ant-upload-list-item-info::before{
  left:0
}

.batch_upload-custom{
  display: flex;
  &_ul{
    display: block;
    flex: 1;
    &.first{
      width: 112px;

      flex: none
    }
  }
}