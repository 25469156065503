@import "../iconfont/iconfont.css";
@import "./variables.scss";

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Source Han Sans CN Regular, -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset
}

img {
  transition: all .5s;
}

a,
img,
i {
  transition: all .5s;
}

* {
  list-style: none;
  padding: 0;
  margin: 0;

  &::-webkit-scrollbar-track-piece {
    background: transparent;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #99a9bf;
    border-radius: 4px;
  }
}

.app-container {
  display: block;
  width: 100vw;
  height: 100%;
  min-width: 1200px;

  .header-custom {
    display: flex;
    width: 100%;
    height: 60px;
    padding: 10px 20px 10px 8px;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background: #fff;
    z-index: 101;
    position: relative;

    .ant-space-item {
      padding: 0 !important
    }

    &__left {
      padding: 4px 0 0 0;
      display: block;

      button {
        display: block;
        padding: 0;
        width: auto;
        height: auto;
        border: none;

        img {
          width: 20px;
          margin: 6px;
        }
      }
    }

    &__right {
      display: block;
      flex: 1;
      padding-top: 4px;
      box-sizing: border-box;

      .ant-space {
        float: right;
      }

      h1 {
        display: inline-block;
      }

      >img {
        width: 120px;
        display: inline-block;
        transform: translateY(5px);
      }

      .ant-btn-link {
        color: #666;
      }

      &__person {
        .ant-popover-inner-content {
          padding: 0 !important;
        }
      }

      .zk-icon {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.25);
      }
    }

    &__menu-drawer {
      >div {
        width: 200px;
        top: 70px;
        font-size: 14px;
      }
    }

    &_message {
      height: 600px;
      width: 400px;
      overflow: hidden;
    }

    &_person {
      height: 400px;
      width: 300px;
      overflow: hidden;
    }
  }

  .MuiTypography-root {
    font-size: 14px;
  }

  button {
    text-transform: none;
  }

  .main-custom {
    height: calc(100vh - 60px);
    overflow-y: auto;
    display: flex;
    background: #F0F3F5;

    &__menu {
      width: 100%;
      height: 100%;
      display: block;
      background: #fff;
      padding-bottom: 40px;
      position: relative;

      &-title {
        display: block;
        padding: 10px 20px;
        font-size: 16px;
        border-bottom: 1px solid #f2f2f2;

        &.center {
          text-align: center;
        }

        img {
          width: 28px;
        }
      }

      &-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: center;
        width: 100%;
        cursor: pointer;
        padding: 13px 0;
        box-shadow: 0 0 4px rgba(0, 0, 0, .1);

        img {
          width: 14px;
          transition: all .5s;

          &.hide {
            transform: rotate(180deg);
          }
        }
      }
    }

    &__center {
      flex: 1;
      display: block;
      // padding: 20px;
      box-sizing: border-box;
      overflow-y: auto;
      position: relative;

      &.zk-pd0 {
        padding: 0
      }

      &.zk-pd20 {
        padding: 20px;
      }
    }

    p {
      padding: 0;
      margin: 0 !important
    }
  }

  .zlink-content__tr {
    text-align: right;
  }

  .zlink-content__tl {
    text-align: left;
  }
}

.header-custom__right__person {
  border-radius: 5px;

  .ant-popover-content {
    border-radius: 5px;

    .ant-popover-inner-content {
      padding: 0;
    }
  }
}

.header-custom__right__person_btn {
  display: flex !important;
  align-items: center;
}

.not_found {
  display: block;
  text-align: center;
  padding-top: 10vh;

  >h1 {
    font-size: 80px;
  }
}

.loading-circular {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .01);
  z-index: 9999;

  .ant-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.loading-linear {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  .ant-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.org-region {
  display: block;
  text-align: center;
  padding: 100px 0;
}

.outlined-input-error {
  color: #d32f2f;
  font-family: Source Han Sans CN Regular, -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, sans-serif !important;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

.home {
  display: block;

  .MuiPaper-root {
    padding: 14px;
    min-height: 110px;
  }
}

.drawer-form {
  display: block;

  .drawer-header {
    display: flex;
    line-height: 40px;
    border-bottom: 1px solid #ccc;
    padding: 10px 20px;

    &__title {
      flex: 1;
      display: block;
      line-height: 40px;
    }
  }

  .drawer-body {
    display: block;
    padding: 10px 20px;
  }
}

.form-component {
  display: block;

  &__li {
    display: flex;
    margin: 20px 0;

    &-label {
      width: 100px;
      display: block;
      line-height: 40px;
      font-size: 14px;
    }

    .MuiFormGroup-root {
      flex: 1;
    }

    &:last-child {
      padding-left: 84px;
    }
  }
}


/* draw标题 */
.zklink-drawer-title {
  padding: 0 0 16px 0;
  font-size: 14px;
  color: #333;
}

/* 省市区插件 */
.org-info-drawer .area-select.medium {
  width: 100% !important;
  max-width: 328px !important;
}

/* drawer边距 */
.ant-drawer-body {
  padding: 16px !important;
}

/* form表单文字左对齐 */
.ant-form-item-label>label::before {
  display: inline-block;
  margin-right: 4px;
  color: #ffffff;
  font-size: 14px;
  font-family: Source Han Sans CN Regular, -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, sans-serif !important;
  line-height: 1;
  content: '*';
}

.area-select.medium {
  width: 100% !important;
}

.zk-submenu__title {
  display: block;
  line-height: 16px;
  font-size: 16px;
  padding: 0 10px;
  margin-bottom: 10px;
  border-left: 3px solid var(--primary-color);
}

.ant-breadcrumb.mb20 {
  margin-bottom: 20px;
}

.zlink-bread-crumb_span {
  cursor: pointer;
}

html {
  --ant-primary-color: 'var(--primary-color)' !important;
}

/* form */
.zk-pro-form {
  .ant-form-item-control-input-content {
    max-width: 328px !important;
    width: 100% !important;
  }
}

#subapp-container {
  display: none;
  height: 100%;

  >div {
    height: 100%;

    >div {
      height: 100%;
    }
  }
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant--menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: var(--primary-color) !important
}



.zk-inner-top-bar {
  display: flex;
  padding: 10px 24px;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 16px;
  line-height: 32px;

  &__left {
    display: block;
    cursor: pointer;
  }

  &__right {
    flex: 1;
    display: block;
    text-align: right;
  }

  .ant-space {
    gap: 4px !important
  }
}

.zk-select-Company {
  display: block;
  border: 1px solid #fff;
  cursor: pointer;
  padding: 3px;
  border-radius: 4px;

  &_icon {
    display: inline-block;
    padding: 3px;
    border-radius: 4px;
    margin-right: 10px;
    width: 28px;
    height: 28px;
    background: #f8f8f8;
    box-sizing: border-box;
    text-align: center;

    i {
      color: #ccc
    }
  }

  &.check {
    border: 1px solid #efefef;

    .zk-select-Company_icon i {
      color: var(--primary-color)
    }
  }
}

.ant-list-grid.zk-select-Company_list .ant-col>.ant-list-item {
  margin-bottom: 5px;
}

.zk-inner-loading {
  display: block;
  text-align: center;
  padding: 40px 0;

  &.mini {
    padding: 0;
  }
}

.zk-form-item-required .ant-form-item-label>label::before {
  color: #ff4d4f
}

.zk-phone-input {
  display: block;
  position: relative;

  &:hover i {
    color: var(--primary-hover-color);
    -webkit-animation: zk-rotateOut 1s .2s ease both;
    -moz-animation: zk-rotateOut 1s .2s ease both;
    animation: zk-rotateOut 1s .2s ease both;
  }

  &__icon {
    position: absolute;
    top: 10px;
    left: 12px;
    z-index: 1;
    text-align: center;
    display: flex;

    &::after {
      content: '';
      display: block;
      width: 1px;
      height: 16px;
      background: #E6E9EB;
      margin: 0 7px 0 10px;
      transform: translate(2px, 5px);
    }
  }

  .ant-select {
    border: none;
    position: absolute;
    top: 2px;
    left: 42px;
    z-index: 1;
    width: 70px;
    height: 36px;
  }

  .ant-input {
    padding-left: 50px;
  }

  &.pl130 .ant-input {
    padding-left: 110px;
  }

  .ant-select-arrow {
    top: 53%
  }

  .ant-select .ant-select-selector {
    background: none !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
    height: 36px !important;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 38px;
  }

  &.hasLabel {
    .ant-select {
      left: 120px;
      height: 30px;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      height: 30px !important;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 30px;
    }

    .ant-input {
      padding-left: 11px;
    }

    &.pl130 .ant-input {
      padding-left: 70px;
    }
  }
}

div#driver-popover-item.zk-driver {
  padding: 24px 24px 14px 24px;
  min-width: 300px;
  max-width: 400px;

  .driver-popover-title {
    font-size: 18px;
    color: #333;
  }

  .driver-popover-description {
    font-size: 14px;
    color: #999;
  }

  .driver-popover-footer {
    margin-top: 40px;
  }

  .driver-close-btn {
    border: none;
    background: none;
    padding: 0;
    color: #999;
  }

  .driver-popover-footer span {
    transform: translateY(-13px);

    button {
      padding: 6px 20px;

      &.driver-next-btn {
        border: none;
        background: var(--primary-color);
        color: #fff;
        text-shadow: none;
      }
    }
  }

  &-highlight {
    margin-left: 10px;

    .driver-close-btn {
      padding: 6px 20px;
      border: none;
      background: var(--primary-color);
      color: #fff;
      text-shadow: none;
    }
  }
}

.zk-notdata {
  display: block;
  font-size: 12px;
  color: #999;

  &.center {
    text-align: center;
    padding: 40px 0;
  }
}

.zk-tourist-modal {
  display: block;
  font-size: 14px;

  .ant-modal-content {
    padding: 0;
    border-radius: 4px;
  }

  strong {
    font-size: 18px;
    padding-bottom: 10px;
    display: block;
  }

  p {
    padding: 0;
    margin: 0;
  }

  &__head {
    display: block;
    height: 150px;
    padding: 24px;
    color: #fff;
    background: url(../images/img_tips_bg.png) no-repeat;

    p {
      color: #f8f8f8;
    }
  }

  ul {
    display: block;
    padding: 10px 24px;
    background: #fff;
    color: #999;

    li {
      padding: 20px 60px 20px 0;
      cursor: pointer;
      position: relative;

      &:first-child {
        border-bottom: 1px solid #ccc;
      }

      strong {
        color: #333;
        padding: 0;
      }

      img {
        position: absolute;
        right: 0;
        top: 20px;
        width: 48px;
      }
    }
  }
}

@keyframes zk-tada {
  0% {
    transform: scale(1)
  }

  10%,
  20% {
    transform: scale(0.9) rotate(-3deg)
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale(1.3) rotate(3deg)
  }

  40%,
  60%,
  80% {
    transform: scale(1.3) rotate(-3deg)
  }

  100% {
    transform: scale(1) rotate(0)
  }
}

@-webkit-keyframes zk-tada {
  0% {
    -webkit-transform: scale(1)
  }

  10%,
  20% {
    -webkit-transform: scale(0.9) rotate(-3deg)
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale(1.3) rotate(3deg)
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale(1.3) rotate(-3deg)
  }

  100% {
    -webkit-transform: scale(1) rotate(0)
  }
}

@-moz-keyframes zk-tada {
  0% {
    -moz-transform: scale(1)
  }

  10%,
  20% {
    -moz-transform: scale(0.9) rotate(-3deg)
  }

  30%,
  50%,
  70%,
  90% {
    -moz-transform: scale(1.3) rotate(3deg)
  }

  40%,
  60%,
  80% {
    -moz-transform: scale(1.3) rotate(-3deg)
  }

  100% {
    -moz-transform: scale(1) rotate(0)
  }
}

@keyframes zk-rotateOut {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes zk-pulse {
  0% {
    transform: rotate(0deg)
  }

  20% {
    transform: rotate(60deg)
  }

  40% {
    transform: rotate(120deg)
  }

  50% {
    transform: rotate(180deg)
  }

  60% {
    transform: rotate(240deg)
  }

  80% {
    transform: rotate(300deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

@keyframes zk-pulse-1 {
  0% {
    -webkit-transform: translateY(0px);
  }

  25% {
    -webkit-transform: translateY(-15px);
  }

  50% {
    -webkit-transform: translateY(15px);
  }

  75% {
    -webkit-transform: translateY(-15px);
  }

  100% {
    -webkit-transform: translateY(15px);
  }
}

.zk-upload-tips {
  display: block;

  span {
    color: $red;
  }

  p {
    color: $black-2;
    margin: 0;

    &.gray {
      color: $black-4
    }
  }
}

.zk-select-Company_list {
  max-height: 200px;
  overflow: hidden auto;
  height: auto !important;

  &.gray {

    // background: #f7f7f7;
    .zk-select-Company {
      border-color: #f7f7f7;
    }
  }

  &-header {
    margin: 20px 0 10px 0;
    padding: 0 12px;
    border: none !important;
    line-height: 20px;
    height: 20px;
    border-left: 3px solid var(--primary-color) !important;
    font-weight: bold;

    span {
      padding-right: 5px;
    }
  }
}

.zk-fingerprint-modal-wrap {
  z-index: 1002;
}

.zk-select-org-person {
  &_right {
    display: block;
    height: 100%;
    padding: 0;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: -24px;
      bottom: -24px;
      width: 1px;
      background: #f0f0f0;
    }
  }

  &_title {
    display: block;
    padding: 0 20px 10px 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid #f0f0f0;

    button {
      float: right;
      margin-top: -7px;
    }
  }

  &_li {
    display: block;
    padding: 0 20px 16px 20px;

    .anticon-user,
    .anticon-folder {
      margin-right: 5px;
    }

    .anticon-close {
      float: right;
      transform: translateY(5px);
      cursor: pointer;
    }
  }

  .ant-input-search {
    margin-bottom: 20px;
  }
}

.zk-vis-add-drawer {
  .ant-radio-group {
    display: flex;
  }

  .ant-radio-button-wrapper {
    flex: 1;
    text-align: center;
  }
}

.zk-vis-info-drawer {
  .ant-tag {
    margin-bottom: 10px;
  }
}

body.en-US {
  .ant-drawer .ant-form.ant-form-vertical>div.ant-row .ant-col-24 {
    text-align: right;
    padding-top: 20px;
  }
}