@font-face {
  font-family: "iconfont"; /* Project id 3276823 */
  src: url('iconfont.woff2?t=1652432390815') format('woff2'),
       url('iconfont.woff?t=1652432390815') format('woff'),
       url('iconfont.ttf?t=1652432390815') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.zlink-ic_fingerprint:before {
  content: "\e624";
}

.zlink-ic_face:before {
  content: "\e626";
}

.zlink-ic_gs:before {
  content: "\e613";
}

.zlink-ic_content_hairpin:before {
  content: "\e640";
}

.zlink-ic_content_uncoupling:before {
  content: "\e641";
}

.zlink-ic_content_returncard:before {
  content: "\e643";
}

.zlink-ic_access_offline:before {
  content: "\e604";
}

.zlink-ic_access_online:before {
  content: "\e603";
}

.zlink-ic_sign_organization:before {
  content: "\e639";
}

.zlink-ic_sign_number:before {
  content: "\e63a";
}

.zlink-ic_sign_scale:before {
  content: "\e63c";
}

.zlink-ic_sign_phone:before {
  content: "\e63d";
}

.zlink-ic_sign_verification:before {
  content: "\e63e";
}

.zlink-ic_sign_type:before {
  content: "\e63f";
}

.zlink-ic_sign_password:before {
  content: "\e637";
}

.zlink-ic_content_add:before {
  content: "\e634";
}

.zlink-ic_content_delete:before {
  content: "\e635";
}

.zlink-ic_content_edit:before {
  content: "\e636";
}

.zlink-ic_content_export:before {
  content: "\e633";
}

.zlink-ic_content_timeslot:before {
  content: "\e631";
}

.zlink-a-ic_content_timeslot1:before {
  content: "\e632";
}

.zlink-ic_content_addpeople1:before {
  content: "\e630";
}

.zlink-ic_content_equipment_issue:before {
  content: "\e62c";
}

.zlink-ic_content_invitation:before {
  content: "\e62e";
}

.zlink-ic_content_equipment_add:before {
  content: "\e638";
}

.zlink-ic_content_addpeople:before {
  content: "\e63b";
}

.zlink-wx:before {
  content: "\e642";
}

