// .ant-table-tbody {

//   >tr {
//     >:last-child {
//       box-shadow: 5px 0 5px -5px rgba(46, 46, 46, 0.481) inset !important;
//     }
//   }
// }

// .ant-table-thead {

//   >tr {
//     .ant-table-cell:nth-last-of-type(2) {
//       box-shadow: 5px 0 5px -5px rgba(46, 46, 46, 0.481) inset !important;
//     }
//   }
// }



.en-US {
  .details-drawer {
    .ant-row {
      flex-direction: column;

      &>div:first-child {
        max-width: 100%;
      }

      &>div:last-child {
        right: 0;
      }
    }
  }
}