.zk-device {
  display: block;

  &__detail {
    min-height: calc(100vh - 145px);
    display: block;
    overflow: hidden;
    background: #fff;
    padding: 24px;
    &.has-bar{
      min-height: calc(100vh - 213px);
    }
    .ant-tabs-content{position: inherit;}
    &-top {
      display: flex;
      padding-top: 20px;

      .left {
        flex: 1;
        display: flex;

        .avatar {
          width: 60px;
          display: block;
        }

        .info {
          flex: 1;
          display: block;
          line-height: 30px;
          padding-left: 10px;

          p {
            margin: 0;
          }
        }
      }

      .right {
        display: block;
      }
    }

    &-parmas {
      .ant-form-item-label {
        text-align: left;
      }
    }
  }

  &__detail-drawer {
    ul {
      display: block;

      li {
        padding: 10px 0;
        display: flex;
        border-bottom: 1px solid #f0f0f0;
        padding-left: 24px;

        >span {
          color: #999;
          padding-right: 20px;
          font-size: 14px;
        }

        i {
          font-size: 30px;
          color: #999;

          &.green {
            color: var(--primary-color);
          }
        }
      }
    }
  }

  &__upload-status {
    display: block;

    li {
      display: flex;
      border-bottom: 1px solid #f0f0f0;
      padding: 10px;

      span {
        display: block;

        &:first-child {
          flex: 1;
        }
      }
    }
  }
}

@media screen and (min-height:600px){
  .zk-device__detail-parmas-btn{
    position: absolute!important;
    bottom: 40px;
    left: 40px;
  }
}

.zk-select-person {
  display: block;

  &__card {
    display: block;
    border: 1px solid #d9d9d9;

    &-top {
      display: block;
      background: #fafafa;
      padding: 10px;
    }

    &-content {
      display: block;
      border-top: 1px solid #d9d9d9;
      background: #fff;
      padding: 10px;
      height: 200px;

      >div {
        overflow-y: auto;
        width: 100%;
        height: 100%;
      }
    }

    &.last {
      border-left: none;

      .zk-select-person__card-top {
        text-align: right;
      }
    }
  }
}

.ant-transfer-list-header {
  background: #fafafa !important;
}

.zk-bt__batch-upload {
  display: block;
  background: #fff;
  border-radius: 4px;
  min-height: 400px;
  height: calc(100vh - 220px);

  &__top {
    display: block;
    padding: 20px;
    height: calc(100% - 75px);
    overflow-y: auto;
  }

  &__footer {
    display: block;
    padding: 10px 20px;
    border-top: 1px solid rgba(0, 0, 0, .06);
    transition: all 0s;
    color: #999;

    ul {
      display: flex;

      li {
        display: block;
        padding: 0 10px 0 0;
        flex: 1;

        &:first-child {
          width: 60%;
          flex: inherit;
        }

        &:last-child {
          flex: inherit;
        }
      }
    }

    .ant-btn.ant-btn-link {
      padding: 0;
      height: 22px;
    }
  }
}